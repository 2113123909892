import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Buttons from "../components/buttons";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Container from "react-bootstrap/Container";
import Know from "../images/more.png";
import Arrow from "../images/mobile-arrow.png";
import Select from "react-select";
import Option from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import * as styles from "../components/index.module.css";
import "../styles/styles.scss";

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`;

const SerotoninPage = () => {
  const changePage = (Option) => {
    window.location.href = "/" + Option.value;
  };
    var defaultChemical = "SEROTONIN";
    var options = [
      {
        value: "histamine",
        label: "HISTAMINE",
      },
      {
        value: "hypocretin",
        label: "HYPOCRETIN (OREXIN)",
      },
      {
        value: "norepinephrine",
        label: "NOREPINEPHRINE",
      },
      {
        value: "dopamine",
        label: "DOPAMINE",
      },
      {
        value: "acetylcholine",
        label: "ACETYLCHOLINE",
      },
    ];

  function MobileMenu() {
    return (
      <Container fluid id="mobile-menu">
        <Container className="nplr">
          <h1>
            There's <img src={Know} /> to Know
          </h1>

          <Select
            className="basic-single"
            classNamePrefix="select"
            //        styles={need class here}
            onChange={changePage}
            name="chemical"
            isSearchable={false}
            placeholder={defaultChemical}
            options={options}
          >
            <Option value="histamine" label="histamine">
              {defaultChemical.toUpperCase()}
            </Option>
          </Select>
        </Container>
      </Container>
    );
  }

  return (
    <Layout>
      <MobileMenu />
    </Layout>
  );
};
/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="There’s More to Know About Serotonin"
    description="Explore serotonin neurons in the brain and learn how direct actions at the amygdala affect cataplexy in narcolepsy."
  />
);

export default SerotoninPage;
